<template>
    <div class="grid">
        <div class="col-12">
            <div class="card">
                <div class="flex flex-row gap-2 align-items-center text-800 mb-1">
                    <AppInfoManual nomeTelaDoManual="veiculos-form" />
                    <h3 class="ml-1">Veículos</h3>
                </div>
                <div class="flex flex-column mb-4 md:flex-row md:justify-content-between md:align-items-center">
                    <div class="p-fluid mt-2 w-full md:mt-0 md:w-5">
                        <div style="display: flex; align-items: center; justify-content: space-between">
                            <InputText v-model="filter" style="width: 80%" placeholder="Pesquisar" @change="search($event)" />
                        </div>
                    </div>
                    <div class="mt-2 ml-auto md:mt-0">
                        <Button label="Novo" icon="pi pi-plus" class="p-button p-button-text mr-2" @click="openBasic" />
                    </div>

                    <Dialog header="Cadastro" v-model:visible="displayBasic" :style="{ width: '45vw' }" ariaCloseLabel>
                        <TabView :scrollable="true" v-model:activeIndex="active">
                            <TabPanel header="Cadastro">
                                <div style="display: flex; justify-content: space-between; margin-bottom: 20px">
                                    <div style="display: flex; flex-direction: column">
                                        <div
                                            style="
                                                display: flex;
                                                max-width: 320px;
                                                min-width: 320px;
                                                align-items: center;
                                                justify-content: space-between;
                                            "
                                        >
                                            <label>Nome</label>
                                            <InputText
                                                required="true"
                                                v-model.trim="form.name"
                                                autofocus
                                                autocomplete="off"
                                                :class="{ 'p-invalid': submitted && !form.name }"
                                            />
                                        </div>
                                        <div
                                            style="
                                                display: flex;
                                                max-width: 345px;
                                                min-width: 345px;
                                                align-items: center;
                                                justify-content: space-between;
                                            "
                                        >
                                            <label>Data de vencimento</label>
                                            <Calendar
                                                id="dataFim"
                                                class="flex px-5 py-3"
                                                v-model="form.dueDate"
                                                dateFormat="dd/mm/yy"
                                                selectionMode="single"
                                                :showIcon="true"
                                                :class="{ 'p-invalid': submitted && !form.dueDate }"
                                                style="display: flex; max-width: 215px; min-width: 215px"
                                            />
                                        </div>
                                        <div
                                            class="field-checkbox"
                                            style="display: flex; align-items: center; margin-top: 20px; justify-content: space-between"
                                        >
                                            <div style="display: flex; align-items: center; justify-content: space-between">
                                                <Checkbox v-model="form.isLeased" :binary="true" />
                                                <div style="margin-left: 10px">
                                                    <label for="binary">Locado</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div style="display: flex; align-items: center; margin-top: 10px; max-width: 320px; min-width: 320px">
                                            <Checkbox v-model="form.isShare" :binary="true" />
                                            <div style="margin-left: 10px">
                                                <label for="binary">Disponibilizar equipamentos para terceiros</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div style="display: flex; flex-direction: column">
                                        <div
                                            style="
                                                display: flex;
                                                max-width: 200px;
                                                min-width: 200px;
                                                align-items: center;
                                                justify-content: space-between;
                                            "
                                        >
                                            <label>Placa</label>
                                            <InputText
                                                id="cnh"
                                                v-model.trim="form.identifierAutomobile"
                                                required="true"
                                                autofocus
                                                autocomplete="off"
                                                :class="{ 'p-invalid': submitted && !form.identifierAutomobile }"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </TabPanel>

                            <!-- veículos / Controle de vencimentos -->
                            <TabPanel :disabled="true" header="Controle de vencimentos" @click="active = 2">
                                <div class="grid">
                                    <div class="col-12">
                                        <div
                                            style="
                                                display: flex;
                                                max-width: 330px;
                                                min-width: 330px;
                                                align-items: center;
                                                justify-content: space-between;
                                            "
                                        >
                                            <label>Data da última revisão</label>
                                            <InputMask
                                                id="CNHDueDate"
                                                mask="99/99/9999"
                                                placeholder="00/00/0000"
                                                required="true"
                                                v-model.trim="form.lastReviewDate"
                                                autofocus
                                                autocomplete="off"
                                            />
                                        </div>
                                        <div
                                            style="
                                                display: flex;
                                                max-width: 330px;
                                                min-width: 330px;
                                                align-items: center;
                                                justify-content: space-between;
                                                margin-top: 10px;
                                            "
                                        >
                                            <label>Data da próxima revisão</label>
                                            <InputMask
                                                id="CNHDueDate"
                                                mask="99/99/9999"
                                                placeholder="00/00/0000"
                                                required="true"
                                                v-model.trim="form.nextReviewDate"
                                                autofocus
                                                autocomplete="off"
                                            />
                                        </div>
                                        <div
                                            style="
                                                display: flex;
                                                max-width: 330px;
                                                min-width: 330px;
                                                align-items: center;
                                                justify-content: space-between;
                                                margin-top: 10px;
                                            "
                                        >
                                            <label>Valor da revisão</label>
                                            <InputText id="name" required="true" v-model.trim="form.nextReviewValue" autofocus autocomplete="off" />
                                        </div>
                                    </div>
                                </div>
                            </TabPanel>
                            -->

                            <!-- veículos / Itens verificados -->
                            <TabPanel :disabled="true" header="Itens verificados" @click="active = 3">
                                <div class="grid">
                                    <div class="col-12">
                                        <div
                                            style="
                                                display: flex;
                                                max-width: 330px;
                                                min-width: 330px;
                                                align-items: center;
                                                justify-content: space-between;
                                            "
                                        >
                                            <label>Nome</label>
                                            <InputText id="name" required="true" v-model.trim="verifiedText" autofocus autocomplete="off" />
                                        </div>
                                        <div
                                            style="
                                                display: flex;
                                                max-width: 330px;
                                                min-width: 330px;
                                                align-items: center;
                                                justify-content: space-between;
                                                margin-top: 10px;
                                            "
                                        >
                                            <label>Periodicidade</label>
                                            <InputText id="name" required="true" v-model.trim="frequencyText" autofocus autocomplete="off" />
                                        </div>
                                        <hr />
                                        <div style="margin-top: 10px" class="mt-2 ml-auto md:mt-0">
                                            <Button @click="addVerified" label="Adicionar" icon="pi pi-plus" class="p-button p-button-text mr-2" />
                                        </div>
                                        <div class="col-12 p-fluid painel painel-2">
                                            <DataTable
                                                dataKey="id"
                                                :value="listCheckedItems"
                                                :row-hover="true"
                                                responsiveLayout="scroll"
                                                breakpoint="640px"
                                                :paginator="true"
                                                :rows="10"
                                                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                                :rowsPerPageOptions="[10, 20, 50]"
                                                currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
                                                @row-click="openModalInfoVerified($event)"
                                                style="cursor: pointer"
                                            >
                                                <template #empty> Nenhum registro encontrado. </template>

                                                <template #loading> Carregando registros. Aguarde ... </template>
                                                <Column :sortable="true" field="listCheckedItems" header="Nome">
                                                    <template #body="slotProps">
                                                        {{ slotProps.data.name }}
                                                    </template>
                                                </Column>
                                                <Column :sortable="true" field="listCheckedItems" header="Periodicidade">
                                                    <template #body="slotProps">
                                                        {{ slotProps.data.period }}
                                                    </template>
                                                </Column>
                                                <Column :sortable="true" header="">
                                                    <template #body="slotProps">
                                                        <Button
                                                            icon="pi pi-ban"
                                                            @click="deleteVerifiel(slotProps.data.id)"
                                                            style="height: 30px; width: 30px"
                                                        />
                                                    </template>
                                                </Column>
                                            </DataTable>
                                        </div>
                                    </div>
                                </div>
                            </TabPanel>
                        </TabView>

                        <!-- botao salvar / voltar -->
                        <AppInactivateDialog
                            customTitle="Tem certeza que deseja inativar o veículo?"
                            v-if="showInactivateRecordDialog"
                            :record="form"
                            :inactivatePath="'/ferramentas/veiculos/inativar/'"
                            :visible="showInactivateRecordDialog"
                            @onConfirm="onClickInactivateRecord"
                            @onClose="onClickCloseInactivateRecord"
                        >
                        </AppInactivateDialog>
                        <AppActivateDialog
                            v-if="showActivateRecordDialog"
                            :record="form"
                            :activatePath="'/ferramentas/veiculos/ativar/'"
                            :visible="showActivateRecordDialog"
                            @onConfirm="onClickActivateRecord"
                            @onClose="onClickCloseActivateRecord"
                        >
                        </AppActivateDialog>
                        <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                            <Button
                                v-if="form.id && !form.ativo"
                                label="Ativar"
                                icon="pi pi-check"
                                class="p-button-outlined p-button-primary mb-5 md:mb-0"
                                @click="activate"
                            />
                            <Button
                                v-if="form.id && form.ativo"
                                label="Inativar"
                                icon="pi pi-ban"
                                class="p-button-outlined p-button-danger mb-5 md:mb-0"
                                @click="inactivate"
                            />
                            <div>
                                <Button label="Voltar" icon="pi pi-times" class="p-button-primary mr-2" @click="closeBasic" />
                                <Button label="Salvar" icon="pi pi-check" class="p-button-primary" @click="save" />
                            </div>
                        </div>
                    </Dialog>

                    <!-- modal de Logs -->
                    <Dialog header="Logs" v-model:visible="displayLogs" :style="{ width: '55vw' }">
                        <div class="grid">
                            <div class="col-12">
                                <div class="card">
                                    <div class="col-12">
                                        <div
                                            style="
                                                display: flex;
                                                max-width: 480px;
                                                min-width: 480px;
                                                align-items: center;
                                                justify-content: space-between;
                                            "
                                        >
                                            <label style="margin-right: 50px">Período</label>
                                            <div class="flex">
                                                <Calendar
                                                    id="dataInicio"
                                                    class="flex py-3"
                                                    v-model="form.dataSugestaoInicial"
                                                    dateFormat="dd/mm/yy"
                                                    selectionMode="single"
                                                    :showIcon="true"
                                                    placeholder="Data Inicio"
                                                />
                                                <Calendar
                                                    id="dataFim"
                                                    class="flex px-5 py-3"
                                                    v-model="form.dataSugestaoFinal"
                                                    dateFormat="dd/mm/yy"
                                                    selectionMode="single"
                                                    :showIcon="true"
                                                    placeholder="Data Fim"
                                                />
                                            </div>
                                        </div>
                                        <div
                                            style="
                                                display: flex;
                                                max-width: 450px;
                                                min-width: 450px;
                                                align-items: center;
                                                justify-content: space-between;
                                                margin-top: 10px;
                                            "
                                        >
                                            <label>Veículo</label>
                                            <Dropdown
                                                v-model="form.scratchs"
                                                style="max-width: 350px; min-width: 350px"
                                                :options="formFilterVehicles"
                                                optionLabel="label"
                                                placeholder="Escolha"
                                            />
                                        </div>
                                        <div style="margin-top: 10px; max-width: 420px; min-width: 420px" class="mt-2 ml-auto md:mt-0">
                                            <Button label="Buscar" icon="pi pi-plus" @click="searchLogs" class="p-button p-button-text mr-2" />
                                        </div>
                                    </div>
                                    <div class="col-12 p-fluid painel painel-2">
                                        <DataTable
                                            dataKey="id"
                                            :value="formLogs"
                                            :row-hover="true"
                                            responsiveLayout="scroll"
                                            breakpoint="640px"
                                            :paginator="true"
                                            :rows="10"
                                            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                            :rowsPerPageOptions="[10, 20, 50]"
                                            currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
                                            style="cursor: pointer"
                                        >
                                            <template #empty> Nenhum registro encontrado. </template>
                                            <template #loading> Carregando registros. Aguarde ... </template>
                                            <Column :sortable="true" field="logs" header="Veículo">
                                                <template #body="slotProps">
                                                    {{ slotProps.data.id }}
                                                </template>
                                            </Column>
                                            <Column :sortable="true" field="logs" header="Data e hora">
                                                <template #body="slotProps">
                                                    {{ formatDate(slotProps.data.updatedAt) }}
                                                </template>
                                            </Column>
                                            <Column :sortable="true" field="logs" header="Ação">
                                                <template #body="slotProps">
                                                    {{ slotProps.data.status }}
                                                </template>
                                            </Column>
                                        </DataTable>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Dialog>
                </div>

                <Toast />
                <!-- listagem de veículos -->
                <AppLoadingWrapper v-if="loading" />
                <div class="col-12 p-fluid painel painel-2">
                    <DataTable
                        v-if="!loading"
                        :value="allVehicles"
                        :row-hover="true"
                        responsiveLayout="scroll"
                        breakpoint="640px"
                        :paginator="true"
                        :rows="10"
                        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                        :rowsPerPageOptions="[10, 20, 50]"
                        currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
                        style="cursor: pointer"
                        :rowClass="defaultRowClass"
                    >
                        <template #empty> Nenhum registro encontrado. </template>

                        <template #loading> Carregando registros. Aguarde ... </template>
                        <Column :sortable="true" header="Código">
                            <template #body="slotProps">
                                <label>
                                    {{ slotProps.data.id }}
                                </label>
                            </template>
                        </Column>
                        <Column :sortable="true" header="Veículo">
                            <template #body="slotProps">
                                <label>
                                    {{ slotProps.data.name }}
                                </label>
                            </template>
                        </Column>
                        <Column :sortable="true" header="Placa">
                            <template #body="slotProps">
                                <label>
                                    {{ slotProps.data.identifierAutomobile }}
                                </label>
                            </template>
                        </Column>
                        <Column :sortable="true" field="period" header="Próxima data de revisão">
                            <template #body="slotProps">
                                <label>
                                    {{ formatDate(slotProps.data.updatedAt) }}
                                </label>
                            </template>
                        </Column>
                        <Column :sortable="true" header="Tipo">
                            <template #body="slotProps">
                                <label>
                                    {{ slotProps.data.isLeased ? 'Locado' : 'Próprio' }}
                                </label>
                            </template>
                        </Column>
                        <Column :sortable="true" header="Terceiros">
                            <template #body="slotProps">
                                <label>
                                    {{ slotProps.data.isShare ? 'Sim' : 'Não' }}
                                </label>
                            </template>
                        </Column>
                        <Column :sortable="true" header="Criado">
                            <template #body="slotProps">
                                <label>
                                    {{ $filters.formatDate(slotProps.data.createdAt) }}
                                    <small class="block text-500"> por {{ slotProps.data?.userCreate?.name }} </small>
                                </label>
                            </template>
                        </Column>
                        <Column :sortable="true" header="Atualizado">
                            <template #body="slotProps">
                                <label>
                                    {{ $filters.formatDate(slotProps.data.updatedAt) }}
                                    <small class="block text-500"> por {{ slotProps.data?.userUpdate?.name }} </small>
                                </label>
                            </template>
                        </Column>
                        <Column bodyClass="text-right" headerStyle="width: 10rem;">
                            <template #body="slotProps">
                                <Button
                                    icon="pi pi-ellipsis-v"
                                    class="p-button-text p-button-secondary"
                                    @click="toggleMenu($event, slotProps.data)"
                                />
                            </template>
                        </Column>
                    </DataTable>
                    <Menu ref="menu" :model="actionItems" :popup="true" />

                    <Dialog :style="{ width: '450px' }" header="Confirmar" :modal="true">
                        <div class="flex align-items-center justify-content-center">
                            <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
                            <span v-if="title"
                                >Tem certeza que deseja excluir <b>{{ title }}</b
                                >?</span
                            >
                        </div>
                        <template #footer>
                            <Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="closeDeleteRecord" />
                            <Button label="Excluir" icon="pi pi-trash" class="p-button-outlined p-button-danger" @click="deleteRecord" />
                        </template>
                    </Dialog>
                    <AppDeleteDialog
                        v-if="showAppDeleteDialog"
                        :visible="showAppDeleteDialog"
                        @onConfirm="deleteEquipament()"
                        @onClose="onClickCloseDeleteRecord"
                    >
                    </AppDeleteDialog>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SecurityService from '../../services/SecurityService';
import { lightFormat, addHours } from 'date-fns';
import { v4 as uuidv4 } from 'uuid';
import AppInfoManual from '../../components/AppInfoManual.vue';

export default {
    data() {
        return {
            actionItems: [
                {
                    label: 'Alterar',
                    icon: 'pi pi-pencil',
                    command: () => this.openModalEditEquip(this.actionRecord)
                },
                {
                    label: 'Excluir',
                    icon: 'pi pi-trash',
                    command: () => this.onClickConfirmDeleteRecord(this.actionRecord)
                }
            ],
            showAppDeleteDialog: false,
            form: {},
            page: 1,
            total: 0,
            totalPage: 0,
            perPage: 10,
            displayBasic: false,
            displayLogs: false,
            active: 0,
            filters: null,
            formInfo: undefined,
            isShare: false,
            isLeased: false,
            submitted: false,
            filter: '',
            loading: false,
            showInactivateRecordDialog: false,
            showActivateRecordDialog: false,
            allVehicles: [],
            allVehiclesDefault: [],
            formLogs: [],
            optionScratchs: [
                { label: '0', value: 0 },
                { label: '1', value: 1 },
                { label: '2', value: 2 },
                { label: '3', value: 3 },
                { label: '4', value: 4 },
                { label: '5', value: 5 },
                { label: '6', value: 6 },
                { label: '7', value: 7 },
                { label: '8', value: 8 },
                { label: '9', value: 9 },
                { label: '10', value: 10 }
            ],
            verifiedText: '',
            frequencyText: '',
            listCheckedItems: [],
            equipsDefault: [],
            formFilterVehicles: []
        };
    },
    watch: {
        page() {
            this.load();
        }
    },
    mounted() {
        this.$service = new SecurityService('/ferramentas/veiculos');
        this.$serviceLogs = new SecurityService('/ferramentas/veiculos/historico');
        this.load();
    },
    methods: {
        onClickConfirmDeleteRecord(record) {
            this.record = record;
            this.showAppDeleteDialog = true;
        },
        onClickCloseDeleteRecord() {
            this.showAppDeleteDialog = false;
        },
        toggleMenu(event, data) {
            this.actionRecord = data;
            this.$refs.menu.toggle(event);
        },
        search() {
            if (this.filter) {
                const GetAllVehicles = this.allVehiclesDefault.filter((v) => String(v.name).toUpperCase().trim().includes(String(this.filter).toUpperCase().trim()) ||
                    String(v.identifierAutomobile).toUpperCase().trim().includes(String(this.filter).toUpperCase().trim()));
                this.allVehicles = GetAllVehicles;
            }
            else {
                this.allVehicles = this.allVehiclesDefault;
            }
        },
        openBasic() {
            this.formInfo = undefined;
            this.active = 0;
            this.form = {};
            this.submitted = false;
            this.displayBasic = true;
        },
        closeBasic() {
            this.displayBasic = false;
            this.form = {};
        },
        openBasicLogs() {
            this.formLogs = [];
            this.form.scratchs = null;
            this.form.dataSugestaoInicial = null;
            this.form.dataSugestaoFinal = null;
            this.displayLogs = true;
        },
        addVerified() {
            if (this.verifiedText && this.frequencyText !== '') {
                this.listCheckedItems.push({
                    name: this.verifiedText,
                    period: this.frequencyText,
                    id: uuidv4()
                });
                (this.verifiedText = ''), (this.frequencyText = '');
            }
        },
        deleteVerifiel(idVerifiel) {
            this.listCheckedItems.splice(idVerifiel, 1);
        },
        formatDate(dateString) {
            const formatDates = lightFormat(addHours(new Date(dateString), 3), 'dd/MM/yyyy');
            const formatHours = lightFormat(addHours(new Date(dateString), 3), 'HH:mm');
            return `${formatDates} às ${formatHours}h`;
        },
        formatInverseDate(dateString) {
            const formatDates = lightFormat(addHours(new Date(dateString), 3), 'yyyy-MM-dd');
            return `${formatDates}`;
        },
        async save() {
            if (this.formInfo?.id) {
                this.saveEdit();
                return;
            }
            try {
                const formatInverseDueDate = this.formatInverseDate(this.form.dueDate);
                this.submitted = true;
                const body = {
                    name: this.form.name,
                    identifierAutomobile: this.form.identifierAutomobile,
                    dueDate: formatInverseDueDate,
                    isLeased: this.form.isLeased || false,
                    isShare: this.form.isShare || false
                };
                const stringBody = JSON.stringify(body);
                await this.$service.save(stringBody);
                this.$toast.add({ severity: 'success', summary: 'Veículo salvo com sucesso!', life: 3000 });
                this.submitted = false;
                this.displayBasic = false;
                this.form = {};
                this.formInfo = undefined;
                this.load();
            }
            catch (error) {
                this.$toast.add({ severity: 'error', summary: 'Problemas ao salvar o veículo!', life: 3000 });
            }
        },
        async openModalEditEquip(itemSelect) {
            const formatlastReviewDate = itemSelect?.lastReviewDate?.split('-');
            const formatNextReviewDate = itemSelect?.nextReviewDate?.split('-');
            const formatDueDate = itemSelect?.dueDate?.split('-');
            this.form = {
                id: itemSelect.id,
                ativo: itemSelect.ativo,
                name: itemSelect?.name,
                identifierAutomobile: itemSelect?.identifierAutomobile,
                dueDate: `${formatDueDate[2]}/${formatDueDate[1]}/${formatDueDate[0]}`,
                isLeased: itemSelect?.isLeased,
                isShare: itemSelect?.isShare,
                lastReviewDate: itemSelect?.lastReviewDate
                    ? `${formatlastReviewDate[2]}/${formatlastReviewDate[1]}/${formatlastReviewDate[0]}`
                    : null,
                nextReviewDate: itemSelect?.nextReviewDate
                    ? `${formatNextReviewDate[2]}/${formatNextReviewDate[1]}/${formatNextReviewDate[0]}`
                    : null,
                nextReviewValue: itemSelect?.nextReviewValue
            };
            this.listCheckedItems = JSON.parse(itemSelect?.listCheckedItems) ? JSON.parse(itemSelect?.listCheckedItems) : this.listCheckedItems;
            this.formInfo = itemSelect;
            this.displayBasic = true;
        },
        async saveEdit() {
            try {
                this.submitted = true;
                const splitBar = (date) => {
                    const activeSplit = date?.split('/');
                    return `${activeSplit[2]}-${activeSplit[1]}-${activeSplit[0]}`;
                };
                const formatInverseDueDate = typeof this.form.dueDate === 'string' ? splitBar(this.form.dueDate) : this.formatInverseDate(this.form.dueDate);
                const formatLastReviewDate = typeof this.form.lastReviewDate === 'string'
                    ? splitBar(this.form.lastReviewDate)
                    : this.formatInverseDate(this.form.lastReviewDate);
                const formatNextReviewDate = typeof this.form.nextReviewDate === 'string'
                    ? splitBar(this.form.nextReviewDate)
                    : this.formatInverseDate(this.form.nextReviewDate);
                const body = {
                    name: this.form.name,
                    identifierAutomobile: this.form.identifierAutomobile,
                    dueDate: formatInverseDueDate,
                    isLeased: this.form.isLeased,
                    isShare: this.form.isShare,
                    listCheckedItems: JSON.stringify(this.listCheckedItems),
                    lastReviewDate: formatLastReviewDate,
                    nextReviewDate: formatNextReviewDate,
                    nextReviewValue: this.form.nextReviewValue
                };
                const stringBody = JSON.stringify(body);
                await this.$service.save(stringBody, this.formInfo?.id);
                this.$toast.add({ severity: 'success', summary: 'Veículo salvo com sucesso!', life: 3000 });
                this.submitted = false;
                this.form = {};
                this.formInfo = undefined;
                this.displayBasic = false;
                this.load();
            }
            catch (error) {
                this.$toast.add({ severity: 'error', summary: 'Problemas ao salvar o veículo!', detail: error?.response?.data?.message, life: 3000 });
            }
        },
        async onClickInactivateRecord() {
            this.form.ativo = false;
            this.onClickCloseInactivateRecord();
            this.load();
        },
        async onClickActivateRecord() {
            this.form.ativo = true;
            this.onClickCloseActivateRecord();
            this.load();
        },
        onClickCloseInactivateRecord() {
            this.showInactivateRecordDialog = false;
        },
        onClickCloseActivateRecord() {
            this.showActivateRecordDialog = false;
        },
        async searchLogs() {
            this.getLogs(this.form.scratchs?.value);
        },
        async inactivate() {
            this.showInactivateRecordDialog = true;
        },
        async activate() {
            this.showActivateRecordDialog = true;
        },
        async deleteEquipament() {
            try {
                await this.$service.remove(this.record?.id);
                this.$toast.add({ severity: 'success', summary: 'Veículo deletado com sucesso!', life: 3000 });
                this.showAppDeleteDialog = false;
                this.load();
            }
            catch (error) {
                this.$toast.add({ severity: 'error', summary: 'ERRO! ' + error.response?.data?.message, life: 3000 });
                this.showAppDeleteDialog = false;
            }
        },
        async getAllVehicles() {
            try {
                const { data } = await this.$service.findAll(null, {});
                const logItemVehicles = data?.items?.map((v) => {
                    if (v) {
                        return {
                            value: v?.id,
                            label: v?.name
                        };
                    }
                });
                this.formFilterVehicles = logItemVehicles;
            }
            catch (error) {
                this.$toast.add({ severity: 'error', summary: 'Problemas ao listar veículo ' + error.message, life: 3000 });
            }
        },
        async getLogs(idLog) {
            try {
                const { data } = await this.$serviceLogs.findById(idLog);
                if (this.form?.dataSugestaoInicial && this.form?.dataSugestaoFinal) {
                    const GetInPeriod = data?.filter((v) => new Date(v?.updatedAt) > new Date(this.form?.dataSugestaoInicial) &&
                        new Date(v?.updatedAt) < new Date(this.form?.dataSugestaoFinal));
                    this.formLogs = GetInPeriod;
                }
                else {
                    this.formLogs = data;
                }
            }
            catch {
                this.$toast.add({ severity: 'error', summary: 'Problemas ao listar logs', life: 3000 });
            }
        },
        async load() {
            try {
                this.loading = true;
                this.getAllVehicles();
                const { data } = await this.$service.findAll(null, {});
                this.allVehicles = data?.items;
                this.allVehiclesDefault = data?.items;
                this.total = data?.meta?.totalItems;
                const calculoPaginacao = data.total / this.perPage;
                this.totalPage = calculoPaginacao === Math.floor(calculoPaginacao) ? calculoPaginacao : Math.floor(calculoPaginacao) + 1;
                this.loading = false;
            }
            catch (error) {
                this.loading = false;
            }
        },
        defaultRowClass(data) {
            if (this.customRowCondition) {
                if (this.customRowCondition(data))
                    return this.customRowStyle(data);
            }
            else {
                if (this.rowClass) {
                    return this.rowClass(data);
                }
                return data.ativo === false ? 'row-inactive' : null;
            }
        }
    },
    components: { AppInfoManual }
};
</script>

<style scoped lang="scss">
::v-deep(.row-inactive) {
    color: #9e9e9e !important;
}
</style>
